<template>
  <v-container fluid class="m-0 p-0">
    <v-row dense class="mt-1" v-if="uc != null">
      <v-col cols="12" class="">
        <v-card class="pb-4" v-bg:B>
          <v-card class="m-0 p-0" flat :color="hexTints(area.cor,0.95)">
            <v-container fluid class="">
              <v-row dense align="center" justify="space-between" class="">
                <v-col cols="auto" class="m-0 p-0">
                  <v-avatar dark :color="hexTints(area.cor,0.88)" size="32" class="m-0 p-0">
                    <i v-i:duo#note#22 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                  </v-avatar>
                </v-col>
                <v-col cols="10" class="m-0 p-0">
                  <div class="mt-0 line-height-1 f-lato fw-600 fs-10pt text-truncate">
                    Ementa
                    <span v-if="qtdeConteudos > 0">
                      <span class="mx-1 fs-10pt fw-200">•</span>
                      <span class="fs-10pt f-roboto fw-300">
                        {{qtdeConteudos}} conteúdos
                      </span>
                      <span class="mx-1 fs-10pt fw-200">•</span>
                      <span class="fs-10pt f-roboto fw-300">2021/2 - 1a. versão</span>
                    </span>
                    <span v-if="qtdeConteudos == 0">
                      <span class="mx-1 fs-10pt fw-200">•</span>
                      <span class="fs-10pt f-roboto fw-300">
                        Aguardando 1a. atualização
                      </span>
                    </span>
                  </div>
                  <div class="line-height-0" v-if="uc.ementa.createdAt != undefined && Object.keys(conteudos).length > 0" :style="'color:'+hexShades(area.cor,.6)">
                    <i v-i:duo#clock#12 class="left mt-0" :style="'color:'+hexShades(area.cor,.6)"></i>
                    <span class="left ms-1 mt-2 f-lato fs-8pt fw-300">atualizado {{$moment(uc.ementa.createdAt).fromNow()}}</span>
                  </div>
                </v-col>
                <v-col cols="1" class="m-0 p-0 text-right pe-1" v-if="!noedit && blocked">
                  <v-badge bordered :color="hexTints(area.cor,.3)" icon="mdi-lock" overlap>
                    <v-btn color="red" class="m-0 p-0" small icon elevation="2" :style="'background-color:'+hexTints(area.cor,.7)" @click="$refs.ementaeditref.show(uc)">
                      <i v-i:duo#pencil#20 v-cHex="hexShades(area.cor,0.2)" class="btn-icon-left ps-1"></i>
                    </v-btn>
                  </v-badge>
                </v-col>
              </v-row>
            </v-container>
          </v-card>

          <v-container fluid class="">
            <v-row dense>
              <v-col cols="12" class="mt-0 p-1 text-justify" v-if="qtdeConteudos == 0">
                <v-alert type="info" v-c:P :color="hexTints(area.cor,0.95)" class="m-0 p-2 mt-2 fs-10pt">
                  Aguardando 1a. atualização
                </v-alert>
              </v-col>
              <v-col cols="12" class="m-0 p-0" v-if="qtdeConteudos > 0">
                <ementaviewformat @estilo="mudaEstilo" @tipo="mudaTipo"/>
              </v-col>
              <v-col cols="12" class="mt-1 p-1" v-if="qtdeConteudos > 0">
                <p v-if="textoTipo=='texto'" :class="'m-0 p-0 f-raleway fs-10pt fw-600 line-height-5 '+textoEstilo">
                  <ementaviewitem :area="area" :conteudo="conteudo" :index="index" v-for="(conteudo,key,index) in conteudos" :key="key+refresh" />
                </p>
                <div class="" v-if="textoTipo=='lista'">
                  <v-card class="p-1 my-1 fs-10pt text-truncate" :color="hexTints(area.cor,0.97)" flat v-for="(conteudo,key,index) in conteudos" :key="key+refresh" >
                    <span class="ms-1 fs-7pt fw-800">ID:</span>
                    <span class="ms-1 me-1 fs-7pt fw-600 f-lato">{{conteudo.id}}</span>
                    <ementaviewitem :area="area" :conteudo="conteudo" :index="index"/>
                  </v-card>
                </div>
                <v-row dense class="mt-3">
                  <v-col cols="12" class="m-0 p-0">
                    <feedcontroler ref="fcontroler" :area="area" :uc="ucSel" :color="area.cor" :rdbPath="ementaPath" :key="refresh"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <ementaedit ref="ementaeditref" :area="area" />
    </v-row>
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray, objToArray } from '@/firebasedb.js'
import readmore from "@/components/readmore.vue"
import feedcontroler from '@/views/feedsComponent/feedControler.vue'
import descontinuarbtn from '@/views/cursos/planos/descontinuarBtn.vue'
import moment from 'moment';
import spanblocked from "./spanBlocked.vue"

export default {
  name: "ementaView",
  components: { readmore, feedcontroler, descontinuarbtn, spanblocked,
    'ementaedit': () => import('./ementaEdit.vue'),
    'ementaviewitem': () => import('./ementa-view-item.vue'),
    'ementaviewformat': () => import('./ementa-view-format.vue'),
  },
  props: {
    noedit: { default: false, type: Boolean },
    parse: { default: false, type: Boolean },
    area: { default: null, type: Object },
    ucSel: { default: null, type: Object },
  },
  computed: {
    blocked() {
      if(this.area != null) {
        var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"]
        if(this.isUserAdmin) return true;
        if(gestores.indexOf(this.userLog.cargo) != -1 && this.userLog.areasIC[this.area.id] != undefined) {
          return true;
        }
      }
      return false;
    }
  },
  data(){
    return {
      uc: null,
      ementaPath: null,
      refresh: 0,
      ementa: {},
      conteudos: {},
      qtdeConteudos: 0,
      textoTipo: "texto",
      textoEstilo: "text-justify",
    }
  },
  watch: {
    ucSel(to, from) {
      var self = this;
      //console.log("ementaView change uc");
      this.buildEmenta();
    },
    $refs(to, from) {
      var self = this;
      //console.log("fcontroler changed");
      self.$refs.fcontroler.build();
    },
  },
  mounted: function() {
    var self = this;
    console.log("userLog",this.userLog);
    this.$store.dispatch("conteudos/init");
    this.buildEmenta();
  },
  methods:{

    buildEmenta() {
      var self = this;
      this.uc = this.ucSel;
      console.log("this.uc",this.uc);
      var ementaPath = "/curriculo/socialData/ucs/"+this.uc.id+"/ementa";
      //console.log(ementaPath);
      this.ementaPath = ementaPath;

      rdb.ref("/curriculo/ucs/"+this.ucSel.id).on('value',function(snapshot) {
        self.uc = snapshot.val();
        //console.log("self.uc",self.uc);
        self.refresh++;
      });

      rdb.ref('/curriculo/conteudos/ucs/'+this.ucSel.id).on('value',function(snapshot) {
        var linha = snapshot.val();
        if(linha == null) {
          self.conteudos = {};
          self.qtdeConteudos = 0;
          self.ordem = "";
        } else {
          self.qtdeConteudos = linha.qtde;
          self.ordem = linha.ordem;
          self.conteudos = {};
          var vetOrdem = [];
          if(linha.ordem != undefined) {
            vetOrdem = linha.ordem.split(";");
          }
          for(var idx in vetOrdem) {
            self.conteudos[vetOrdem[idx]] = linha.conteudos[vetOrdem[idx]];
          }
        }
        self.refresh++;
        console.log("self.conteudos",self.conteudos);
      });
    },

    descontinuar(novoTexto) {
      //console.log("novoTexto");
      //console.log(novoTexto);
      var ementaObj = Object.assign({}, this.uc.ementa);
      ementaObj.emProducao = false;
      //console.log(ementaObj);
      var pathUrl = "/curriculo/ucs/"+this.uc.id+"/ementa";
      //console.log("pushKey on: ",pathUrl);
      var newKey, itemNew;
      newKey = rdb.ref(pathUrl+"/descontinuados/").push().key;
      //console.log("put com nova chave: "+newKey);
      ementaObj.id = newKey;
      itemNew = {
        createdAt: moment().format(),
        emProducao: true,
        texto: novoTexto,
        descontinuados: {}
      };
      if(ementaObj.descontinuados == undefined) {
        itemNew.descontinuados[newKey] = ementaObj;
      } else {
        var descontinuados = Object.assign({}, ementaObj.descontinuados);
        delete ementaObj.descontinuados;
        itemNew.descontinuados = descontinuados;
        itemNew.descontinuados[newKey] = ementaObj;
      }
      //console.log(itemNew);
      var updates = {};
      updates[pathUrl] = itemNew;
      rdb.ref().update(updates);
      //console.log("pushKey success!");
    },

    novoTexto(texto) {
      //console.log("novoTexto",texto);
      //console.log("this.uc",this.uc);
      var ementa = clone2(this.uc.ementa);
      ementa.createdAt = moment().format();
      ementa.texto = texto;
      ementa.nova = true;
      //console.log("ementa",ementa);
      var updates = {};
      updates["/curriculo/ucs/"+this.uc.id+"/ementa"] = ementa;
      //console.log("updates",updates);
      rdb.ref().update(updates);
      this.toastS("Ementa atualizada com sucesso!");
    },

    mudaEstilo(estilo) {
      console.log("mudaEstilo",estilo);
      this.textoEstilo = estilo;
    },

    mudaTipo(tipo) {
      console.log("mudaTipo",tipo);
      this.textoTipo = tipo;
    }
  }
}
</script>

<style scoped>

</style>
